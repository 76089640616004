<div class="modal-header">
    <h5 class="modal-title fonte-Roboto">{{ title }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <h6 class="fonte-Roboto">{{ message | translate}}</h6>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-cancelar" style="min-width: 120px !important;" (click)="decline()">{{ btnCancelText }}</button>
    <button type="button" class="btn btn-{{typeButton}}" style="min-width: 120px !important;" (click)="accept()">{{ btnOkText }}</button>
</div>
