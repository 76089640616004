import { Estado } from './../endereco.model';
import { Cidade, Pais } from "../endereco.model";
import { Aeroporto } from "./aeroporto.model";
import { BuscaAereoRQ, Aereo } from "./aereo.model";
import { BuscaHotelRQ } from "./hotel.model";
import { BuscaServicoRQ } from "./servico.model";
import { BuscaSeguroRQ } from './seguro.model';
import { BuscaCarroRQ } from './carro.model';
import { BuscarCasaRQ } from './casa.model';

import * as moment from "moment";
import { USA_LOJA } from "../../app.config";
import { BuscarCircuitoRQ } from './circuito.model';
import { LojasCarro } from '../gerenciamento/lojascarro.model';

export enum TIPOBUSCAAEREO {
    idaVolta = "ida-volta",
    ida = "ida",
    surface = "surface",
    multiplosTrechos = "multiplos-trechos"
}

export class Opcoes {
    public gol: boolean = true;
    public azul: boolean = true;
    public amadeus: boolean = true;
    public sabre: boolean = true;
    public diretos: boolean = false;
    public ConexaoLonga: boolean = true;

    public TodasFamilias: boolean = false;
    public resultadosAereos: number = 9999;
    public resultadosHoteis: number = 9999;
}

export class Buscas {
    public aereo: boolean = false;
    public hotel: boolean = false;
    public transfer: boolean = false;
    public carro: boolean = false;
    public ticket: boolean = false;
    public seguro: boolean = false;
    public servico: boolean = false;
    public loja: boolean = USA_LOJA;
    public pacote: boolean = false;
    public casa: boolean = false;
    public pacoteOM : boolean = false;
    public circuito: boolean = false;
}

export class Trecho {
    public cidadeOrigem: Cidade;
    public cidadeDestino: Cidade;
    public AeroportoPartida: Aeroporto;
    public AeroportoChegada: Aeroporto;
    public dataIda: any;
    public dataVolta: any;
    public minDataIda: any;
    public minDataVolta: any;

    constructor() {
        this.cidadeOrigem = new Cidade();
        this.cidadeDestino = new Cidade();
        this.AeroportoPartida = new Aeroporto();
        this.AeroportoChegada = new Aeroporto();
        this.minDataIda = moment().add(1, 'days');
    }
}

export class Quarto {
    public qtdeAdultos: number;
    public qtdeIdosos: number;
    public qtdeCriancas: number;
    public idadesCriancas: number[] = [];
    public nacionalidadePaxQuarto: any;

    constructor() {
        this.qtdeIdosos = 0;
        this.qtdeAdultos = 2;
        this.qtdeCriancas = 0;
    }
}

export class Busca {
    primeira: boolean = true;
    novoOrcamento: boolean = true;
    orcamentoId: number = 0;
    ReservaId: number = 0;
    tipoBuscaAereo: TIPOBUSCAAEREO;
    tipoBusca: Buscas;
    destinoCodigo = '';
    localOrigem: LojasCarro = new LojasCarro();
    localOrigemTipo: string;
    dataOrigem: any;
    dataDevolucao: any;
    horaOrigem = 10;
    minutoOrigem = 0;
    localDevolucao: LojasCarro = new LojasCarro();
    Devolucao: LojasCarro = new LojasCarro();
    localDevolucaoTipo: string;
    horaDevolucao = 10;
    minutoDevolucao = 0;
    buscaBloqueio?: boolean = false;
    FornecedoresCircuito: string[] = ["ST", "EM"];

    somenteCarro: boolean = false;
    somenteSeguro: boolean = false;
    trechos: Trecho[];
    quartos: Quarto[];
    AgenciaId: number;
    opcoesAvancadas: Opcoes;
    // passos: any[] = [];
    BuscaPacote: boolean = false;
    guid: string;
    guidAereo: string;
    guidHotel: string;
    PacoteId = 0;
    EventoId = 0;

    public nacionalidadePax: Pais;

    aereo: Aereo;

    nrnoites = 0;

    usuario: any;

    constructor() {
        this.tipoBusca = new Buscas();
        this.opcoesAvancadas = new Opcoes();
        this.quartos = [];
        this.quartos.push(new Quarto());
        this.trechos = [];
        this.trechos.push(new Trecho());
        this.nacionalidadePax = new Pais();
        this.tipoBuscaAereo = TIPOBUSCAAEREO.idaVolta;
    }

    getQtdeQuartos(): number {
        if (this.quartos != undefined) {
            return this.quartos.length;
        } else return 0;
    }

    getQtdeAdultos(): number {
        let soma = 0;
        if (this.quartos != undefined) {
            for (let i = 0; i < this.quartos.length; i++) {
                soma += this.quartos[i].qtdeAdultos;
                soma += this.quartos[i].qtdeIdosos;
            }
            for (let i = 0; i < this.quartos.length; i++) {
                soma += this.quartos[i].idadesCriancas.filter(x => x > 11).length;
            }
        }
        return soma;
    }

    getQtdeCriancas(): number {
        let soma = 0;
        if (this.quartos != undefined) {
            for (let i = 0; i < this.quartos.length; i++) {
                soma += this.quartos[i].qtdeCriancas;
            }
        }
        return soma;
    }

    getQtdeChd(): number {
        let soma = 0;
        if (this.quartos != undefined) {
            for (let i = 0; i < this.quartos.length; i++) {
                soma += this.quartos[i].idadesCriancas.filter(x => x > 1 && x < 12).length;
            }
        }
        return soma;
    }

    getQtdeInf(): number {
        let soma = 0;
        if (this.quartos != undefined) {
            for (let i = 0; i < this.quartos.length; i++) {
                soma += this.quartos[i].idadesCriancas.filter(x => x < 2).length;
            }
        }
        return soma;
    }

    getIdadesChdInf(): string {
      let idades = '';
      if (this.quartos != undefined) {
        for (const quarto of this.quartos) {
          const idadesCriancas = quarto.idadesCriancas;
          for (const idade of idadesCriancas) {
            idades += idade + ',';
          }
        }
      }
      return idades;
    }

    decodeBusca(json: string): Busca {
        const buscaDecodificada = JSON.parse(json);
        const busca = Object.create(Busca.prototype);
        return Object.assign(busca, buscaDecodificada);
    }

    getBuscaHotel(): BuscaHotelRQ {
        const buscaHotel = new BuscaHotelRQ();
        buscaHotel.AgenciaId = this.AgenciaId;
        buscaHotel.Guid = this.guidHotel;
        buscaHotel.Nacionalidade = this.nacionalidadePax.Iata;
        buscaHotel.PacoteId = this.PacoteId;
        buscaHotel.EventoId = this.EventoId;
        for (let i = 0; i < this.quartos.length; i++) {
            buscaHotel.Quartos.push({
                NumeroPesquisa: i + 1,
                Qtde: 1,
                Adultos: this.quartos[i].qtdeAdultos + this.quartos[i].qtdeIdosos,
                Criancas: this.quartos[i].idadesCriancas
            });
        }
        if (((buscaHotel.PacoteId && buscaHotel.PacoteId > 0) || (buscaHotel.EventoId && buscaHotel.EventoId > 0))) {
              this.tipoBusca.aereo = false;
            }
        if ((this.tipoBusca.aereo || this.tipoBusca.pacoteOM) && this.primeira) {
            // buscaHotel.Cidade.CidadeId = this.trechos[0].cidadeDestino.Id;
            switch (this.tipoBuscaAereo) {
                case TIPOBUSCAAEREO.ida:
                    if (this.primeira) {
                    }
                    else {
                        buscaHotel.Checkin = this.trechos[0].dataIda;
                    }

                    buscaHotel.Checkout = this.trechos[0].minDataVolta;
                    buscaHotel.Cidade.Id = this.trechos[0].AeroportoChegada.CidadeId;
                    break;

                case TIPOBUSCAAEREO.idaVolta:
                    if (this.primeira && this.aereo != undefined) {
                        let data = moment(this.aereo.Seguimentos[0].Voos[this.aereo.Seguimentos[0].Voos.length - 1].DataChegada.toString(), "YYYY-MM-DD HH:mm");

                        // Para voos chegando após 4 da manhã, subtrai um dia na diária do hotel
                        if (data.hour() >= 4) {
                            buscaHotel.Checkin = data;
                        }
                        else {
                            buscaHotel.Checkin = data.subtract(1, "day");
                        }

                        if (this.aereo.Fornecedor != "BLA" && this.aereo.Subfornecedor != "BLA") {
                            data = moment(this.aereo.Seguimentos[1].Voos[0].DataPartida.toString(), "YYYY-MM-DD HH:mm");

                            //Pega a data de retorno, e não faz nenhuma lógica como a data do checkin
                            buscaHotel.Checkout = data;
                        }
                        else {
                            let Seguimento = this.aereo.Seguimentos[0];

                            buscaHotel.Checkin = moment(Seguimento.DataPartida);
                            buscaHotel.Checkout = moment(Seguimento.DataChegada);
                        }

                    }
                    else {
                        buscaHotel.Checkin = this.trechos[0].dataIda;
                        buscaHotel.Checkout = this.trechos[0].dataVolta;
                    }

                    buscaHotel.Cidade.Id = this.trechos[0].AeroportoChegada.CidadeId;
                    break;

                case TIPOBUSCAAEREO.surface:
                    if (this.primeira) {
                    }
                    else {
                        buscaHotel.Checkin = this.trechos[0].dataIda;
                    }
                    if (this.trechos.length > 1) {

                        let data = moment(this.aereo.Seguimentos[0].Voos[this.aereo.Seguimentos[0].Voos.length - 1].DataChegada.toString(), "YYYY-MM-DD HH:mm");

                        // Para voos chegando após 4 da manhã, subtrai um dia na diária do hotel
                        if (data.hour() >= 4) {
                            buscaHotel.Checkin = data;
                        }
                        else {
                            buscaHotel.Checkin = data.subtract(1, "day");
                        }

                        buscaHotel.Checkout = this.trechos[this.trechos.length - 1].dataIda;

                        this.trechos[0].dataVolta = this.trechos[this.trechos.length - 1].dataIda;
                    }
                    else {
                        buscaHotel.Checkout = this.trechos[0].minDataVolta;
                    }

                    buscaHotel.Cidade.Id = this.trechos[0].AeroportoChegada.CidadeId;
                    break;

                case TIPOBUSCAAEREO.multiplosTrechos:
                    if (this.primeira) {
                    }
                    else {
                        buscaHotel.Checkin = this.trechos[0].dataIda;
                    }
                    if (this.trechos.length > 1) {
                        let data = moment(this.aereo.Seguimentos[0].Voos[this.aereo.Seguimentos[0].Voos.length - 1].DataChegada.toString(), "YYYY-MM-DD HH:mm");

                        // Para voos chegando após 4 da manhã, subtrai um dia na diária do hotel
                        if (data.hour() >= 4) {
                            buscaHotel.Checkin = data;
                        }
                        else {
                            buscaHotel.Checkin = data.subtract(1, "day");
                        }

                        buscaHotel.Checkout = this.trechos[this.trechos.length - 1].dataIda;

                        this.trechos[0].dataVolta = this.trechos[this.trechos.length - 1].dataIda;
                    }
                    else {
                        buscaHotel.Checkout = this.trechos[0].minDataVolta;
                    }
                    buscaHotel.Cidade.Id = this.trechos[0].AeroportoChegada.CidadeId;
                    break;
            }
        }
        else {
            buscaHotel.Checkin = this.trechos[0].dataIda;
            buscaHotel.Checkout = this.trechos[0].dataVolta;
            buscaHotel.Cidade.Id = this.trechos[0].cidadeDestino.Id;
        }

        if (this.tipoBusca.pacoteOM) {
          this.trechos[0].dataIda = buscaHotel.Checkin;
           this.trechos[0].dataVolta = buscaHotel.Checkout;
        }

        buscaHotel.Checkin = buscaHotel.Checkin.format("YYYY-MM-DD");
        buscaHotel.Checkout = buscaHotel.Checkout.format("YYYY-MM-DD");

        if (this.tipoBusca.pacote || this.tipoBusca.pacoteOM) {
          buscaHotel.BuscaPacote = true;
        }
        else {
          buscaHotel.BuscaPacote = false;
        }

        return buscaHotel;
    }

    getBuscaSeguro(): BuscaSeguroRQ {
        const buscaSeguro = new BuscaSeguroRQ();
        buscaSeguro.AgenciaId = this.AgenciaId;
        buscaSeguro.Guid = this.guid;
        buscaSeguro.Adt = 0;
        buscaSeguro.Snr = 0;
        buscaSeguro.Chd = 0;
        for (const quarto of this.quartos) {
            buscaSeguro.Adt += quarto.qtdeAdultos;
            buscaSeguro.Snr += quarto.qtdeIdosos;
            buscaSeguro.Chd += quarto.qtdeCriancas;
        }
        buscaSeguro.DestinoCodigo = this.destinoCodigo;
        if (this.tipoBusca.aereo) {
            switch (this.tipoBuscaAereo) {
                case TIPOBUSCAAEREO.ida:
                    if (this.primeira) {
                    } else {
                        buscaSeguro.Partida = this.trechos[0].dataIda;
                    }
                    buscaSeguro.Retorno = this.trechos[0].minDataVolta;
                    break;

                case TIPOBUSCAAEREO.idaVolta:
                    if (this.primeira && this.aereo != undefined) {
                        let data = moment(this.aereo.Seguimentos[0].Voos[this.aereo.Seguimentos[0].Voos.length - 1].DataChegada.toString(), 'YYYY-MM-DD HH:mm');

                        // Para voos chegando após 4 da manhã, subtrai um dia na diária do hotel
                        if (data.hour() >= 4) {
                            buscaSeguro.Partida = data;
                        } else {
                            buscaSeguro.Partida = data.subtract(1, 'day');
                        }

                        data = moment(this.aereo.Seguimentos[1].Voos[0].DataPartida.toString(), 'YYYY-MM-DD HH:mm');
                        // Pega a data de retorno, e não faz nenhuma lógica como a data do checkin
                        buscaSeguro.Retorno = data;
                    } else {
                        buscaSeguro.Partida = this.trechos[0].dataIda;
                        buscaSeguro.Retorno = this.trechos[0].dataVolta;
                    }
                    break;

                case TIPOBUSCAAEREO.surface:
                    if (!this.primeira) {
                        buscaSeguro.Partida = this.trechos[0].dataIda;
                    }
                    if (this.trechos.length > 1) {
                        const data = moment(this.aereo.Seguimentos[0].Voos[this.aereo.Seguimentos[0].Voos.length - 1].DataChegada.toString(), 'YYYY-MM-DD HH:mm');
                        // Para voos chegando após 4 da manhã, subtrai um dia na diária do hotel
                        if (data.hour() >= 4) {
                            buscaSeguro.Partida = data;
                        } else {
                            buscaSeguro.Partida = data.subtract(1, 'day');
                        }
                        buscaSeguro.Retorno = this.trechos[this.trechos.length - 1].dataIda;
                        this.trechos[0].dataVolta = this.trechos[this.trechos.length - 1].dataIda;
                    } else {
                        buscaSeguro.Retorno = this.trechos[0].minDataVolta;
                    }
                    break;

                case TIPOBUSCAAEREO.multiplosTrechos:
                    if (!this.primeira) {
                        buscaSeguro.Partida = this.trechos[0].dataIda;
                    }
                    if (this.trechos.length > 1) {

                        const data = moment(this.aereo.Seguimentos[0].Voos[this.aereo.Seguimentos[0].Voos.length - 1].DataChegada.toString(), 'YYYY-MM-DD HH:mm');
                        // Para voos chegando após 4 da manhã, subtrai um dia na diária do hotel
                        if (data.hour() >= 4) {
                            buscaSeguro.Partida = data;
                        } else {
                            buscaSeguro.Partida = data.subtract(1, 'day');
                        }

                        buscaSeguro.Retorno = this.trechos[this.trechos.length - 1].dataIda;
                        this.trechos[0].dataVolta = this.trechos[this.trechos.length - 1].dataIda;

                    } else {
                        buscaSeguro.Retorno = this.trechos[0].minDataVolta;
                    }
                    break;
            }
        } else {
            buscaSeguro.Partida = this.trechos[0].dataIda;
            buscaSeguro.Retorno = this.trechos[0].dataVolta;
        }

        buscaSeguro.Partida = buscaSeguro.Partida.format("YYYY-MM-DD");
        buscaSeguro.Retorno = buscaSeguro.Retorno.format("YYYY-MM-DD");

        return buscaSeguro;
    }

    getBuscaServico(): BuscaServicoRQ {
        const buscaServico = new BuscaServicoRQ();
        buscaServico.PacoteId = this.PacoteId;
        if (this.ReservaId != undefined) {
            buscaServico.ReservaId = this.ReservaId;
        }

        buscaServico.AgenciaId = this.AgenciaId;
        buscaServico.Guid = this.guid;
        buscaServico.Adt = 0;
        buscaServico.IdadesChd = [];
        for (let i = 0; i < this.quartos.length; i++) {
            buscaServico.Adt += this.quartos[i].qtdeAdultos + this.quartos[i].qtdeIdosos;
            buscaServico.IdadesChd = [
                ...buscaServico.IdadesChd,
                ...this.quartos[i].idadesCriancas
            ];
        }

        if (this.tipoBusca.aereo) {
            switch (this.tipoBuscaAereo) {
                case TIPOBUSCAAEREO.ida:
                    if (this.primeira) {
                    }
                    else {
                        buscaServico.De = this.trechos[0].dataIda;
                    }
                    buscaServico.Ate = this.trechos[0].minDataVolta;
                    buscaServico.Cidade.Id = this.trechos[0].AeroportoChegada.CidadeId;
                    break;

                case TIPOBUSCAAEREO.idaVolta:
                    if (this.primeira && this.aereo != undefined) {
                        let data = moment(this.aereo.Seguimentos[0].Voos[this.aereo.Seguimentos[0].Voos.length - 1].DataChegada.toString(), "YYYY-MM-DD HH:mm");

                        // Para voos chegando após 4 da manhã, subtrai um dia na diária do hotel
                        if (data.hour() >= 4) {
                            buscaServico.De = data;
                        }
                        else {
                            buscaServico.De = data.subtract(1, "day");
                        }

                        if (this.aereo.Fornecedor != "BLA") {
                            data = moment(this.aereo.Seguimentos[1].Voos[0].DataPartida.toString(), "YYYY-MM-DD HH:mm");

                            //Pega a data de retorno, e não faz nenhuma lógica como a data do checkin
                            buscaServico.Ate = data;
                        }
                        else {
                            let Seguimento = this.aereo.Seguimentos[0];

                            buscaServico.De = moment(Seguimento.DataPartida);
                            buscaServico.Ate = moment(Seguimento.DataChegada);

                            this.trechos[0].dataIda = buscaServico.De;
                            this.trechos[0].dataVolta = buscaServico.Ate;
                        }

                    }
                    else {
                        buscaServico.De = this.trechos[0].dataIda;
                        buscaServico.Ate = this.trechos[0].dataVolta;
                    }

                    buscaServico.Cidade.Id = this.trechos[0].AeroportoChegada.CidadeId;
                    break;

                case TIPOBUSCAAEREO.surface:
                    if (this.primeira) {
                    }
                    else {
                        buscaServico.De = this.trechos[0].dataIda;
                    }
                    if (this.trechos.length > 1) {
                        let data = moment(this.aereo.Seguimentos[0].Voos[this.aereo.Seguimentos[0].Voos.length - 1].DataChegada.toString(), "YYYY-MM-DD HH:mm");

                        // Para voos chegando após 4 da manhã, subtrai um dia na diária do hotel
                        if (data.hour() >= 4) {
                            buscaServico.De = data;
                        }
                        else {
                            buscaServico.De = data.subtract(1, "day");
                        }

                        buscaServico.Ate = this.trechos[this.trechos.length - 1].dataIda;

                        this.trechos[0].dataVolta = this.trechos[this.trechos.length - 1].dataIda;

                    }
                    else {
                        buscaServico.Ate = this.trechos[0].minDataVolta;
                    }

                    buscaServico.Cidade.Id = this.trechos[0].AeroportoChegada.CidadeId;
                    break;

                case TIPOBUSCAAEREO.multiplosTrechos:
                    if (this.primeira) {
                    }
                    else {
                        buscaServico.De = this.trechos[0].dataIda;
                    }
                    if (this.trechos.length > 1) {

                        let data = moment(this.aereo.Seguimentos[0].Voos[this.aereo.Seguimentos[0].Voos.length - 1].DataChegada.toString(), "YYYY-MM-DD HH:mm");
                        // Para voos chegando após 4 da manhã, subtrai um dia na diária do hotel
                        if (data.hour() >= 4) {
                            buscaServico.De = data;
                        }
                        else {
                            buscaServico.De = data.subtract(1, "day");
                        }

                        buscaServico.Ate = this.trechos[this.trechos.length - 1].dataIda;

                        this.trechos[0].dataVolta = this.trechos[this.trechos.length - 1].dataIda;

                    }
                    else {
                        buscaServico.Ate = this.trechos[0].minDataVolta;
                    }

                    buscaServico.Cidade.Id = this.trechos[0].AeroportoChegada.CidadeId;
                    break;
            }
        }
        else {
            buscaServico.De = this.trechos[0].dataIda;
            buscaServico.Ate = this.trechos[0].dataVolta;
            buscaServico.Cidade.Id = this.trechos[0].cidadeDestino.Id;
        }

        buscaServico.De = buscaServico.De.format("YYYY-MM-DD");
        buscaServico.Ate = buscaServico.Ate.format("YYYY-MM-DD");

        return buscaServico;
    }

    getBuscaAereo(): BuscaAereoRQ {
        const buscaAereo = new BuscaAereoRQ();
          buscaAereo.PacoteId = this.PacoteId;
        if (this.ReservaId == undefined) {
            buscaAereo.Adt = this.getQtdeAdultos();
            buscaAereo.Chd = this.getQtdeChd();
            buscaAereo.Inf = this.getQtdeInf();
            buscaAereo.Idades = this.getIdadesChdInf();
        } else {
            buscaAereo.Idades = this.getIdadesChdInf();
            buscaAereo.ReservaId = this.ReservaId;
        }
        buscaAereo.AgenciaId = this.AgenciaId;

        buscaAereo.MaximoRegistros = this.opcoesAvancadas.resultadosAereos;
        buscaAereo.FiltroAereo.TodasFamilias = !this.opcoesAvancadas.TodasFamilias;
        buscaAereo.FiltroAereo.SomenteVoosDiretos = this.opcoesAvancadas.diretos;
        buscaAereo.FiltroAereo.ConexaoLonga = this.opcoesAvancadas.ConexaoLonga;
        buscaAereo.SegmentosBusca = [];
        buscaAereo.TipoBusca = this.tipoBuscaAereo;
        if (this.PacoteId > 0) {
          const x = this.trechos[0];
          buscaAereo.SegmentosBusca.push({
            AeroportoPartida: x.AeroportoPartida.toLocaleString(),
            AeroportoChegada: x.AeroportoChegada.toLocaleString(),
            PaisChegada: null,
            DataPartida: x.dataIda.format("YYYY-MM-DD"),
          });
          buscaAereo.SegmentosBusca.push({
            AeroportoPartida: x.AeroportoChegada.toLocaleString(),
            AeroportoChegada: x.AeroportoPartida.toLocaleString(),
            PaisChegada: null,
            DataPartida: x.dataVolta.format("YYYY-MM-DD"),
          });
        }
        else if (this.tipoBuscaAereo == TIPOBUSCAAEREO.idaVolta) {
            const x = this.trechos[0];
            buscaAereo.SegmentosBusca.push({
                AeroportoPartida: x.AeroportoPartida.Iata,
                AeroportoChegada: x.AeroportoChegada.Iata,
                PaisChegada: null,
                DataPartida: x.dataIda.format("YYYY-MM-DD")
            });
            buscaAereo.SegmentosBusca.push({
                AeroportoPartida: x.AeroportoChegada.Iata,
                AeroportoChegada: x.AeroportoPartida.Iata,
                PaisChegada: null,
                DataPartida: x.dataVolta.format("YYYY-MM-DD")
            });
        } else {
            for (let i = 0; i < this.trechos.length; i++) {
                const x = this.trechos[i];
                buscaAereo.SegmentosBusca.push({
                    AeroportoPartida: x.AeroportoPartida.Iata,
                    AeroportoChegada: x.AeroportoChegada.Iata,
                    PaisChegada: null,
                    DataPartida: x.dataIda.format("YYYY-MM-DD")
                });
            }
        }

        if (this.tipoBusca.pacote || this.tipoBusca.pacoteOM) {
          buscaAereo.BuscaPacote = true;
        }
        else {
          buscaAereo.BuscaPacote = false;
        }

        buscaAereo.Guid = this.guidAereo;

        return buscaAereo;
    }

    getBuscaCarro(): BuscaCarroRQ {
        const buscaCarro = new BuscaCarroRQ();

        if (this.ReservaId != undefined) {
            buscaCarro.ReservaId = this.ReservaId;
        }

        buscaCarro.LocalOrigem = this.localOrigem.Sigla;
        buscaCarro.LocalDevolucao = this.localDevolucao.Sigla;
        buscaCarro.DataHoraOrigem = this.dataOrigem;
        buscaCarro.DataHoraDevolucao = this.dataDevolucao;
        buscaCarro.localOrigemTipo = this.localOrigem.Tipo;
        buscaCarro.localDevolucaoTipo = this.localDevolucao.Tipo;

        buscaCarro.AgenciaId = this.AgenciaId;
        buscaCarro.Guid = this.guid;
        buscaCarro.Adt = 0;
        buscaCarro.IdadesChd = [];
        for (let i = 0; i < this.quartos.length; i++) {
            buscaCarro.Adt += this.quartos[i].qtdeAdultos + this.quartos[i].qtdeIdosos;
            buscaCarro.IdadesChd = [
                ...buscaCarro.IdadesChd,
                ...this.quartos[i].idadesCriancas
            ];
        }

        if (this.tipoBusca.aereo) {
            switch (this.tipoBuscaAereo) {
                case TIPOBUSCAAEREO.ida:
                    if (!this.primeira) {
                        buscaCarro.DataHoraOrigem = moment(this.trechos[0].dataIda);
                    }
                    buscaCarro.DataHoraDevolucao = moment(this.trechos[0].minDataVolta);
                    buscaCarro.Cidade.Id = this.trechos[0].AeroportoChegada.CidadeId;
                    // buscaCarro.LocalOrigem.Id = this.trechos[0].AeroportoChegada.Id;
                    buscaCarro.LocalOrigem = this.trechos[0].AeroportoChegada.Iata;
                    buscaCarro.localOrigemTipo = "A";
                    // buscaCarro.LocalOrigem.Nome = this.trechos[0].AeroportoChegada.Cidade.Descricao + " - " + this.trechos[0].AeroportoChegada.Cidade.Estado.Iata + " - " + this.trechos[0].AeroportoChegada.Cidade.Estado.Pais.Nome;
                    // buscaCarro.LocalDevolucao.Id = this.trechos[0].AeroportoChegada.Id;
                    buscaCarro.LocalDevolucao = this.trechos[0].AeroportoChegada.Iata;
                    buscaCarro.localDevolucaoTipo = "A";
                    // buscaCarro.LocalDevolucao.Nome = this.trechos[0].AeroportoChegada.Cidade.Descricao + " - " + this.trechos[0].AeroportoChegada.Cidade.Estado.Iata + " - " + this.trechos[0].AeroportoChegada.Cidade.Estado.Pais.Nome;
                    break;

                case TIPOBUSCAAEREO.idaVolta:
                    if (this.primeira && this.aereo != undefined) {
                        let data = moment(this.aereo.Seguimentos[0].Voos[this.aereo.Seguimentos[0].Voos.length - 1].DataChegada.toString(), "YYYY-MM-DD HH:mm");

                        // Para voos chegando após 4 da manhã, subtrai um dia na diária do hotel
                        if (data.hour() >= 4) {
                            buscaCarro.DataHoraOrigem = data;
                        }
                        else {
                            buscaCarro.DataHoraOrigem = data.subtract(1, "day");
                        }

                        if (this.aereo.Fornecedor != "BLA") {
                            data = moment(this.aereo.Seguimentos[1].Voos[0].DataPartida.toString(), "YYYY-MM-DD HH:mm");

                            //Pega a data de retorno, e não faz nenhuma lógica como a data do checkin
                            buscaCarro.DataHoraDevolucao = data;
                        }
                        else {
                            let Seguimento = this.aereo.Seguimentos[0];

                            buscaCarro.DataHoraOrigem = moment(Seguimento.DataPartida);
                            buscaCarro.DataHoraDevolucao = moment(Seguimento.DataChegada);

                            this.trechos[0].dataIda = buscaCarro.DataHoraOrigem;
                            this.trechos[0].dataVolta = buscaCarro.DataHoraDevolucao;
                        }

                    }
                    else {
                        buscaCarro.DataHoraOrigem = this.trechos[0].dataIda;
                        buscaCarro.DataHoraDevolucao = this.trechos[0].dataVolta;
                    }

                    buscaCarro.Cidade.Id = this.trechos[0].AeroportoChegada.CidadeId;
                    // buscaCarro.LocalOrigem.Id = this.trechos[0].AeroportoChegada.Id;
                    buscaCarro.LocalOrigem = this.trechos[0].AeroportoChegada.Iata;
                    // buscaCarro.LocalOrigem.Nome = this.trechos[0].AeroportoChegada.Cidade.Descricao + " - " + this.trechos[0].AeroportoChegada.Cidade.Estado.Iata + " - " + this.trechos[0].AeroportoChegada.Cidade.Estado.Pais.Nome;
                    // buscaCarro.LocalDevolucao.Id = this.trechos[0].AeroportoChegada.Id;
                    buscaCarro.LocalDevolucao = this.trechos[0].AeroportoChegada.Iata;
                    // buscaCarro.LocalDevolucao.Nome = this.trechos[0].AeroportoChegada.Cidade.Descricao + " - " + this.trechos[0].AeroportoChegada.Cidade.Estado.Iata + " - " + this.trechos[0].AeroportoChegada.Cidade.Estado.Pais.Nome;
                    break;

                case TIPOBUSCAAEREO.surface:
                    if (this.primeira) {
                    }
                    else {
                        buscaCarro.DataHoraOrigem = this.trechos[0].dataIda;
                    }
                    if (this.trechos.length > 1) {
                        let data = moment(this.aereo.Seguimentos[0].Voos[this.aereo.Seguimentos[0].Voos.length - 1].DataChegada.toString(), "YYYY-MM-DD HH:mm");

                        // Para voos chegando após 4 da manhã, subtrai um dia na diária do hotel
                        if (data.hour() >= 4) {
                            buscaCarro.DataHoraOrigem = data;
                        }
                        else {
                            buscaCarro.DataHoraOrigem = data.subtract(1, "day");
                        }

                        buscaCarro.DataHoraDevolucao = this.trechos[this.trechos.length - 1].dataIda;

                        this.trechos[0].dataVolta = this.trechos[this.trechos.length - 1].dataIda;

                    }
                    else {
                        buscaCarro.DataHoraDevolucao = this.trechos[0].minDataVolta;
                    }

                    buscaCarro.Cidade.Id = this.trechos[0].AeroportoChegada.CidadeId;
                    // buscaCarro.LocalOrigem.Id = this.trechos[0].AeroportoChegada.Id;
                    buscaCarro.LocalOrigem = this.trechos[0].AeroportoChegada.Iata;
                    buscaCarro.localOrigemTipo = "A";
                    buscaCarro.LocalDevolucao = "A";
                    // buscaCarro.LocalOrigem.Nome = this.trechos[0].AeroportoChegada.Cidade.Descricao + " - " + this.trechos[0].AeroportoChegada.Cidade.Estado.Iata + " - " + this.trechos[0].AeroportoChegada.Cidade.Estado.Pais.Nome;
                    // buscaCarro.LocalDevolucao.Id = this.trechos[0].AeroportoChegada.Id;
                    buscaCarro.LocalDevolucao = this.trechos[0].AeroportoChegada.Iata;

                    // buscaCarro.LocalDevolucao.Nome = this.trechos[0].AeroportoChegada.Cidade.Descricao + " - " + this.trechos[0].AeroportoChegada.Cidade.Estado.Iata + " - " + this.trechos[0].AeroportoChegada.Cidade.Estado.Pais.Nome;
                    break;

                case TIPOBUSCAAEREO.multiplosTrechos:
                    if (this.primeira) {
                    }
                    else {
                        buscaCarro.DataHoraOrigem = this.trechos[0].dataIda;
                    }
                    if (this.trechos.length > 1) {

                        let data = moment(this.aereo.Seguimentos[0].Voos[this.aereo.Seguimentos[0].Voos.length - 1].DataChegada.toString(), "YYYY-MM-DD HH:mm");
                        // Para voos chegando após 4 da manhã, subtrai um dia na diária do hotel
                        if (data.hour() >= 4) {
                            buscaCarro.DataHoraOrigem = data;
                        }
                        else {
                            buscaCarro.DataHoraOrigem = data.subtract(1, "day");
                        }

                        buscaCarro.DataHoraDevolucao = this.trechos[this.trechos.length - 1].dataIda;

                        this.trechos[0].dataVolta = this.trechos[this.trechos.length - 1].dataIda;

                    }
                    else {
                        buscaCarro.DataHoraDevolucao = this.trechos[0].minDataVolta;
                    }

                    buscaCarro.Cidade.Id = this.trechos[0].AeroportoChegada.CidadeId;
                    buscaCarro.LocalOrigem = this.trechos[0].AeroportoChegada.Iata;
                    buscaCarro.LocalDevolucao = this.trechos[0].AeroportoChegada.Iata;
                    buscaCarro.localOrigemTipo = "A";
                    buscaCarro.LocalDevolucao = "A";
                    break;
            }
        }
        else {
            if (this.somenteCarro) {
                buscaCarro.Cidade.Id = this.localOrigem.CidadeId;
                buscaCarro.LocalOrigem = this.localOrigem.Sigla;
                buscaCarro.LocalDevolucao = this.localDevolucao.Sigla;
                buscaCarro.localDevolucaoTipo = this.localDevolucao.Tipo;
                buscaCarro.localOrigemTipo = this.localOrigem.Tipo;
            } else if (this.tipoBusca.hotel || this.tipoBusca.servico || this.tipoBusca.seguro) {
                buscaCarro.DataHoraOrigem = this.trechos[0].dataIda;
                buscaCarro.DataHoraDevolucao = this.trechos[0].dataVolta;
                buscaCarro.Cidade.Id = this.trechos[0].cidadeDestino.Id;
                buscaCarro.LocalOrigem = this.trechos[0].AeroportoChegada.Iata;
                buscaCarro.LocalDevolucao = this.trechos[0].AeroportoChegada.Iata;
                buscaCarro.localOrigemTipo = "A";
                buscaCarro.LocalDevolucao = "A";
            }

        }

        buscaCarro.DataHoraOrigem = moment(buscaCarro.DataHoraOrigem).startOf('day').add(this.horaOrigem, 'hours').add(this.minutoOrigem, 'minutes').format("YYYY-MM-DD HH:mm");
        buscaCarro.DataHoraDevolucao = moment(buscaCarro.DataHoraDevolucao).startOf('day').add(this.horaDevolucao, 'hours').add(this.minutoDevolucao, 'minutes').format("YYYY-MM-DD HH:mm");
        this.dataOrigem = moment(buscaCarro.DataHoraOrigem);
        this.dataDevolucao = moment(buscaCarro.DataHoraDevolucao);

        return buscaCarro;
    }

    getBuscaCasa(): BuscarCasaRQ {
        const buscarCasaRQ = new BuscarCasaRQ();
        buscarCasaRQ.AgenciaId = this.AgenciaId;
        buscarCasaRQ.Guid = this.guid;
        buscarCasaRQ.Pessoas = 0;

        for (const quarto of this.quartos) {
            buscarCasaRQ.Pessoas += quarto.qtdeAdultos + quarto.qtdeIdosos + quarto.qtdeCriancas;
        }

        buscarCasaRQ.Quartos = this.quartos.length;

        if (this.tipoBusca.aereo) {
            switch (this.tipoBuscaAereo) {
                case TIPOBUSCAAEREO.ida:
                    if (this.primeira) {
                    } else {
                        buscarCasaRQ.Checkin = this.trechos[0].dataIda;
                    }
                    buscarCasaRQ.Checkout = this.trechos[0].minDataVolta;
                    buscarCasaRQ.Cidade.Id = this.trechos[0].AeroportoChegada.CidadeId;
                    break;

                case TIPOBUSCAAEREO.idaVolta:
                    if (this.primeira && this.aereo != undefined) {
                        let data = moment(this.aereo.Seguimentos[0].Voos[this.aereo.Seguimentos[0].Voos.length - 1].DataChegada.toString(), 'YYYY-MM-DD HH:mm');

                        // Para voos chegando após 4 da manhã, subtrai um dia na diária do hotel
                        if (data.hour() >= 4) {
                            buscarCasaRQ.Checkin = data;
                        } else {
                            buscarCasaRQ.Checkin = data.subtract(1, 'day');
                        }

                        data = moment(this.aereo.Seguimentos[1].Voos[0].DataPartida.toString(), 'YYYY-MM-DD HH:mm');
                        // Pega a data de retorno, e não faz nenhuma lógica como a data do checkin
                        buscarCasaRQ.Checkout = data;
                    } else {
                        buscarCasaRQ.Checkin = this.trechos[0].dataIda;
                        buscarCasaRQ.Checkout = this.trechos[0].dataVolta;
                    }

                    buscarCasaRQ.Cidade.Id = this.trechos[0].AeroportoChegada.CidadeId;
                    break;

                case TIPOBUSCAAEREO.surface:
                    if (!this.primeira) {
                        buscarCasaRQ.Checkin = this.trechos[0].dataIda;
                    }
                    if (this.trechos.length > 1 && this.aereo != undefined) {
                        const data = moment(this.aereo.Seguimentos[0].Voos[this.aereo.Seguimentos[0].Voos.length - 1].DataChegada.toString(), 'YYYY-MM-DD HH:mm');
                        // Para voos chegando após 4 da manhã, subtrai um dia na diária do hotel
                        if (data.hour() >= 4) {
                            buscarCasaRQ.Checkin = data;
                        } else {
                            buscarCasaRQ.Checkin = data.subtract(1, 'day');
                        }
                        buscarCasaRQ.Checkout = this.trechos[this.trechos.length - 1].dataIda;
                        this.trechos[0].dataVolta = this.trechos[this.trechos.length - 1].dataIda;
                    } else {
                        buscarCasaRQ.Checkout = this.trechos[0].minDataVolta;
                    }

                    buscarCasaRQ.Cidade.Id = this.trechos[0].AeroportoChegada.CidadeId;
                    break;

                case TIPOBUSCAAEREO.multiplosTrechos:
                    if (!this.primeira) {
                        buscarCasaRQ.Checkin = this.trechos[0].dataIda;
                    }
                    if (this.trechos.length > 1) {

                        const data = moment(this.aereo.Seguimentos[0].Voos[this.aereo.Seguimentos[0].Voos.length - 1].DataChegada.toString(), 'YYYY-MM-DD HH:mm');
                        // Para voos chegando após 4 da manhã, subtrai um dia na diária do hotel
                        if (data.hour() >= 4) {
                            buscarCasaRQ.Checkin = data;
                        } else {
                            buscarCasaRQ.Checkin = data.subtract(1, 'day');
                        }

                        buscarCasaRQ.Checkout = this.trechos[this.trechos.length - 1].dataIda;
                        this.trechos[0].dataVolta = this.trechos[this.trechos.length - 1].dataIda;

                    } else {
                        buscarCasaRQ.Checkout = this.trechos[0].minDataVolta;
                    }

                    buscarCasaRQ.Cidade.Id = this.trechos[0].AeroportoChegada.CidadeId;
                    break;
            }
        } else {
            buscarCasaRQ.Checkin = this.trechos[0].dataIda;
            buscarCasaRQ.Checkout = this.trechos[0].dataVolta;
            buscarCasaRQ.Cidade.Id = this.trechos[0].cidadeDestino.Id;
        }

        buscarCasaRQ.Checkin = buscarCasaRQ.Checkin.format("YYYY-MM-DD");
        buscarCasaRQ.Checkout = buscarCasaRQ.Checkout.format("YYYY-MM-DD");

        return buscarCasaRQ;
    }

    getBuscaCircuito(): BuscarCircuitoRQ {
        const buscarCircuitoRQ = new BuscarCircuitoRQ();
        buscarCircuitoRQ.AgenciaId = this.AgenciaId;
        buscarCircuitoRQ.Guid = this.guid;
        buscarCircuitoRQ.Fornecedores = this.FornecedoresCircuito;
        // buscarCircuitoRQ.Pessoas = 0;

        // for (const quarto of this.quartos) {
        //     buscarCircuitoRQ.Pessoas += quarto.qtdeAdultos + quarto.qtdeIdosos + quarto.qtdeCriancas;
        // }

        // buscarCircuitoRQ.Quartos = this.quartos.length;

        // if (this.tipoBusca.aereo) {
        //     switch (this.tipoBuscaAereo) {
        //         case TIPOBUSCAAEREO.ida:
        //             if (this.primeira) {
        //             } else {
        //                 buscarCircuitoRQ.Saida = this.trechos[0].dataIda;
        //             }
        //             buscarCircuitoRQ.Chegada = this.trechos[0].minDataVolta;
        //             buscarCircuitoRQ.Cidade.Id = this.trechos[0].AeroportoChegada.CidadeId;
        //             break;

        //         case TIPOBUSCAAEREO.idaVolta:
        //             if (this.primeira) {
        //                 let data = moment(this.aereo.Seguimentos[0].Voos[this.aereo.Seguimentos[0].Voos.length - 1].DataChegada.toString(), 'YYYY-MM-DD HH:mm');

        //                 // Para voos chegando após 4 da manhã, subtrai um dia na diária do hotel
        //                 if (data.hour() >= 4) {
        //                     buscarCircuitoRQ.Saida = data;
        //                 } else {
        //                     buscarCircuitoRQ.Saida = data.subtract(1, 'day');
        //                 }

        //                 data = moment(this.aereo.Seguimentos[1].Voos[0].DataPartida.toString(), 'YYYY-MM-DD HH:mm');
        //                 // Pega a data de retorno, e não faz nenhuma lógica como a data do checkin
        //                 buscarCircuitoRQ.Chegada = data;
        //             } else {
        //                 buscarCircuitoRQ.Saida = this.trechos[0].dataIda;
        //                 buscarCircuitoRQ.Chegada = this.trechos[0].dataVolta;
        //             }

        //             buscarCircuitoRQ.Cidade.Id = this.trechos[0].AeroportoChegada.CidadeId;
        //             break;

        //         case TIPOBUSCAAEREO.surface:
        //             if (!this.primeira) {
        //                 buscarCircuitoRQ.Saida = this.trechos[0].dataIda;
        //             }
        //             if (this.trechos.length > 1) {
        //                 const data = moment(this.aereo.Seguimentos[0].Voos[this.aereo.Seguimentos[0].Voos.length - 1].DataChegada.toString(), 'YYYY-MM-DD HH:mm');
        //                 // Para voos chegando após 4 da manhã, subtrai um dia na diária do hotel
        //                 if (data.hour() >= 4) {
        //                     buscarCircuitoRQ.Saida = data;
        //                 } else {
        //                     buscarCircuitoRQ.Saida = data.subtract(1, 'day');
        //                 }
        //                 buscarCircuitoRQ.Chegada = this.trechos[this.trechos.length - 1].dataIda;
        //                 this.trechos[0].dataVolta = this.trechos[this.trechos.length - 1].dataIda;
        //             } else {
        //                 buscarCircuitoRQ.Chegada = this.trechos[0].minDataVolta;
        //             }

        //             buscarCircuitoRQ.Cidade.Id = this.trechos[0].AeroportoChegada.CidadeId;
        //             break;

        //         case TIPOBUSCAAEREO.multiplosTrechos:
        //             if (!this.primeira) {
        //                 buscarCircuitoRQ.Saida = this.trechos[0].dataIda;
        //             }
        //             if (this.trechos.length > 1) {

        //                 const data = moment(this.aereo.Seguimentos[0].Voos[this.aereo.Seguimentos[0].Voos.length - 1].DataChegada.toString(), 'YYYY-MM-DD HH:mm');
        //                 // Para voos chegando após 4 da manhã, subtrai um dia na diária do hotel
        //                 if (data.hour() >= 4) {
        //                     buscarCircuitoRQ.Saida = data;
        //                 } else {
        //                     buscarCircuitoRQ.Saida = data.subtract(1, 'day');
        //                 }

        //                 buscarCircuitoRQ.Chegada = this.trechos[this.trechos.length - 1].dataIda;
        //                 this.trechos[0].dataVolta = this.trechos[this.trechos.length - 1].dataIda;

        //             } else {
        //                 buscarCircuitoRQ.Chegada = this.trechos[0].minDataVolta;
        //             }

        //             buscarCircuitoRQ.Cidade.Id = this.trechos[0].AeroportoChegada.CidadeId;
        //             break;
        //     }
        // } else {
            buscarCircuitoRQ.Saida = this.trechos[0].dataIda;
            // buscarCircuitoRQ.Chegada = this.trechos[0].dataVolta;
            // buscarCircuitoRQ.Cidade.Id = this.trechos[0].cidadeDestino.Id;
        // }

        buscarCircuitoRQ.Saida = buscarCircuitoRQ.Saida.format("YYYY-MM-DD");
        // buscarCircuitoRQ.Chegada = buscarCircuitoRQ.Chegada.format("YYYY-MM-DD");

        return buscarCircuitoRQ;
    }

}
