
export class LojasCarro {
  public Id: number;
  public Cidade?: any;
  public CidadeId: number;
  public Sigla : string;
  public Nome : string;
  public Tipo: string;
  public Endereco : string = "";
  public Longitude: string;
  public Latitude: string;
  public display_field: string = "";
}

export class OfflineLojasCarroPickup {
  public Id: any;
  public OfflineCarro: any;
  public OfflineCarroId: any;
  public LojaCarroOrigem: any;
  public LojaCarroOrigemId: any;
  public LojaCarroDestino: any;
  public LojaCarroDestinoId: any;
  public ValorPickup: number = 0;
  public ValorTaxa: number = 0;
  public display_field: string = "";
}
