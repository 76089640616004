import { Agencia } from './../cadastro/agencia.model';
import { ServicoOffline } from '../gerenciamento/servicooffline.model';
import { Hotel } from '../turismo/hotel.model';

export class Pacote {
    public Id: number;
    public Nome: string;
    public Nome_Es: string;
    public Nome_En: string;
    public Cidade: any = { Nome: "" };
    public CidadeId: number;
    public Moeda: any;
    public MoedaId: number;
    public ValorServico: number;
    public ValorTaxa: number;
    public ValidadeDe: any;
    public ValidadeAte: any;
    public DadosVoucher: string;
    public DadosVoucher_Es: string;
    public DadosVoucher_En: string;
    public Imagem: string;
    public Introducao: string;
    public Introducao_Es: string;
    public Introducao_En: string;
    public MinimoDeNoites: number;
    public Dias: number;
    public QuantidadeChdFree: number;
    public IdadeChdFree: number;
    public Ativo: boolean;
    public Observacao: string;
    public Observacao_Es: string;
    public Observacao_En: string;
    public PacoteHoteis: PacoteHotel[];
    public PacotesInclui: PacoteInclui[];
    public PacotePeriodos: PacotePeriodo[];
    public PacoteImagens: PacoteImagem[];
    public PacoteTarifas: PacoteTarifa[];
    public AgenciasExclusivas: Agencia[];
    public Consultores: any[];
    public BloqueioAereo: any[];
    public OfflineServicos: ServicoOffline[];
    public Fixo: boolean;
    public ControlaQuantidade: boolean = false;
    public ControlaPorCategoria: boolean = false;
    public Disney: boolean = false;
    public Checkin: any;
    public Checkout: any;
    public Multa: any;
    public DataLimite: any;
    public Fornecedor: any;
    public PacoteTipos: any;
    public Markup: any;
    public ValorServicoChd1: number;
    public QuantidadeDisponivel: number;
    public Chd1IdadeDe: number;
    public Chd1IdadeAte: number;
    public ReferenciaInterna: string;
    public Destaque: boolean;
    public CidadeSaida: string;
    public PacoteDestinoId: number;
    public PacoteTarifasAgrupadas: PacoteTarifasAgrupadas[];
    public Casamento: boolean = false;


}

export class PacoteReserva {
  public Id: number;
  public Checkin: any;
  public Checkout: any;
  public Adt: number;
  public Chd: number;
  public CodigoFornecedor: string;
  public CidadeId: number;
  public IdadesChd: number[] = [];
  public Nome: string;
  public Pacote: Pacote;
  public Status: number;
  public Hoteis: Hotel[];
  public Aereos: any;
  public Servicos: any;
}



export class PacoteInclui {
  public Id: number;
  public Titulo: string;
  public Titulo_Es: string;
  public Titulo_En: string;
  public PacoteId: number;
  public Descritivo: string;
  public Descritivo_Es: string;
  public Descritivo_En: string;
}

export class PacotePeriodo {
  public Id: number;
  public PacoteId: number;
  public CheckinDe: any;
  public CheckoutAte: any;

}
export class PacoteHotel {
  public Id: number;
  public Pacote: Pacote;
  public PacoteId: number;
  public WebServiceId: number;
  public WebService: any = { Descricao: "" };
  public HotelIntegrador: string;
  public TarifaEspecifica: string;
  public NomeHotel: string;
}

export class PacoteImagem {
  public Id: number;
  public Imagem: string;
  public PacoteHotelID: number;
}

export class PacoteTipo {
  public Id: number;
  public Descricao: string;
  public Descricao_Es: string;
  public Descricao_En: string;
}

export class PacoteTarifa {
  public Id: number;
  public Nome: string;
  public Descricao: string;
  public ValorNet: number;
  public ValorVenda: number;
  public Quantidade: number;
  public Pacote: Pacote;
  public PacoteId: number;
  public AdtMax: number;
  public ChdMax: number;
  public IdadeDe: number;
  public IdadeAte: number;
  public TipoPessoa: any;
  public selecionada: boolean;
  public IdadesChd : number[] = [];
  public RangeIdades : number[] = [];

}
export class PacoteTarifasAgrupadas {
  public Nome: string;
  public PacoteTarifa: PacoteTarifa[];
  public selecionada: boolean;
}
export class PacoteDestino {
  public Id: number;
  public Descricao: string;
  public Descricao_Es: string;
  public Descricao_En: string;
  public PacoteDestinoPaiId: number;
  public SubPacoteDestinos: PacoteDestino[];
  public UrlAmigavel: string;

}
export class Pacote_BloqueioAereo {
  BloqueioAereo?: any = { Descricao: '' };
}

export class Pacote_Agencia {
  public Agencia?: any = { NomeFantasia: '' };
}

export class Pacote_Consultor {
  public Consultor?: any = { Nome: '' };
}

export class BuscaPacoteRQ {
  public Tipo: any[];
}
