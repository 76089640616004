import { Agencia } from '../cadastro/agencia.model';
import { Cidade } from '../endereco.model';
import { LojasCarro, OfflineLojasCarroPickup } from '../gerenciamento/lojascarro.model';
import { WebService } from '../gerenciamento/webservice.model';

export class Carro {
    public Id: number;
    public selecionado: boolean;
    public Descricao: string;
    public Codigo: string;
    public ModeloCodigo: string;
    public ModeloNome: string;
    public Pax: number;
    public Bagagem: number;
    public TemAirCondicionado: boolean;
    public TransmissaoTipo: string;
    public Categoria: string;
    public Portas: number;
    public Imagem: string;
    public CondutorDocumento: string;
    public CondutorEmail: string;
    public CondutorNome: string;
    public CondutorSobrenome: string;
    public CondutorTelefone: string;
    public Status: any;
    public EnderecoOrigem: any;
    public EnderecoDevolucao: any;
    public DataHoraOrigem: any;
    public DataHoraDevolucao: any;
    public MoedaFechamento: any;
    public ValorListagem: any;
    public Fornecedor: any;
    public Tracao: any;
    public KmLivre: any;
    public Tipo: any;
    public EnderecoLatOrigem: any;
    public EnderecoLongOrigem: any;
    public EnderecoLatDevolucao: any;
    public EnderecoLongDevolucao: any;
    public LocalizadorIntegrador: any;
    public CodigoCliente: any;
    public PoliticaCancelamento: any;
}

export class BuscaCarroRQ {
    public LocalOrigem: any;
    public LocalDevolucao: any;
    public localOrigemTipo: any;
    public localDevolucaoTipo: any;
    public DataHoraOrigem: any;
    public DataHoraDevolucao: any;
    public Moeda: string;
    public Guid: string;
    public Rebusca: boolean;
    public AgenciaId: number;
    public Nacionalidade: string;
    public ReservaId: number;
    public Adt: number;
    public IdadesChd: number[] = [];
    public Cidade: Cidade = new Cidade();
}

export class FiltroCarro {
    public ValorMinimo: number;
    public ValorMaximo: number;
    public LocalOrigem: string = "";
    public LocalDevolucao: string = "";
    public Fornecedores: string[] = [];
}

// export class LojasCarros {
//     public Id: number;
//     public Sigla: string = "";
//     public Nome: string = "";
//     public Endereco: string = "";
//     public Tipo: string = "";
//     public Longitude: string = "";
//     public Latitude: string = "";
//     public CidadeId: number;
//     public display_field: string = "";
// }

// export class OfflineLojasCarros {
//     public Id: number;
//     public Nome: string = "";
//     public Endereco: string = "";
//     public Latitude: string = "";
//     public Longitude: string = "";
//     public Codigo: string = "";
//     public Sigla: string = "";
//     public Tipo: string = "";
//     public CidadeId: number;
//     public display_field: string = "";
// }

export class OfflineCarroTipo {
    public Id: number;
    public Codigo: string = "";
    public Descricao: string = "";
    public Descricao_En: string = "";
    public Descricao_Es: string = "";
    public display_field: string = "";
}

export class OfflineCarroTransmissao {
    public Id: number;
    public Codigo: string = "";
    public Descricao: string = "";
    public Descricao_En: string = "";
    public Descricao_Es: string = "";
    public display_field: string = "";
}


export class OfflineCarroTracao {
    public Id: number;
    public Codigo: string = "";
    public Descricao: string = "";
    public Descricao_En: string = "";
    public Descricao_Es: string = "";
    public display_field: string = "";
}
export class OfflineCarroCombustivel {
    public Id: number;
    public Codigo: string = "";
    public Descricao: string = "";
    public Descricao_En: string = "";
    public Descricao_Es: string = "";
    public display_field: string = "";
}

export class OfflineCarroCategoria {
    public Id: number;
    public OfflineCarroId: number;
    public OfflineCarro: OfflineCarro;
    public Codigo: string = "";
    public Descricao: string = "";
    public Descricao_En: string = "";
    public Descricao_Es: string = "";
    public QuantidadeDisponivel: number | undefined;
    public ControlePorQuantidade: boolean = false;
    public display_field: string = "";
    public Tarifas: OfflineCarroCategoriaTarifa[] = [];
    public Disponibilidades: OfflineCarroCategoriaDisponibilidade[] = [];

    public Tarifa: {};
    public Disponibilidade: OfflineCarroCategoriaDisponibilidade = new OfflineCarroCategoriaDisponibilidade();
}
export class OfflineCarroCategoriaTarifa {
    public Id: number;
    public Codigo: string = "";
    public Descricao: string = "";
    public De: string = "";
    public Ate: string = "";
    public ValidadeDe: string = "";
    public ValidadeAte: string = "";
    public ValorDia: number;
    public ValorHora: number;
    public ValorPickup: number;
    public Moeda: any;
    public MoedaId: any;
    public Ativo: boolean;
    public OfflineCarroCategoria: OfflineCarroCategoria;
    public OfflineCarroCategoriaId: number;
    public display_field: string = "";
}

export class OfflineCarro {
    public Id: number;
    public EmailFornecedor: string = "";
    public Titulo: string = "";
    public Titulo_En: string = "";
    public Titulo_Es: string = "";
    public Descricao: string = "";
    public Descricao_En: string = "";
    public Descricao_Es: string = "";
    public ImagemPrincipal: string = "";
    public Ativo: boolean = true;
    public Destaque: boolean = false;
    public ArCondicionado: boolean = false;
    public inputKmLivre: boolean = false;
    public QuantidadeDisponivel: number = 0;
    public QuantidadePessoas: number = 0;
    public ERPId: number = 0;
    public QuantidadePortas: number = 0;
    public Bagagem: number = 0;
    public Moeda: any;
    public KmLivre: boolean = false;
    public PrazoCancelamento: number = 0;
    public PoliticaCancelamento: string = "";
    public PoliticaCancelamento_En: string = "";
    public PoliticaCancelamento_Es: string = "";
    public OfflineCarroCategoria: OfflineCarroCategoria = new OfflineCarroCategoria();
    public OfflineCarroTipo: OfflineCarroTipo = new OfflineCarroTipo();
    public OfflineCarroTracao: OfflineCarroTracao = new OfflineCarroTracao();
    public OfflineCarroCombustivel: OfflineCarroCombustivel = new OfflineCarroCombustivel();
    public OfflineCarroTransmissao: OfflineCarroTransmissao = new OfflineCarroTransmissao();
    public LojasVinculadas: LojasCarro[] = [];
    public LojasPickups: OfflineLojasCarroPickup[] = [];
    public WebServices: WebService[] = [];
    public AgenciasExclusivas: Agencia[] = [];
    public Imagens = [];
    public ManutencaoPeriodo: OfflineCarroManutencaoPeriodo[] = [];
    public PromocaoTarifa: OfflineCarroPromocaoTarifa[] = [];
}

export class OfflineCarroCategoriaDisponibilidade {
    public OfflineCarroCategoria: any;
    public OfflineCarroCategoriaId: any;
    public Quantidade: any;
    public Operador: any; 
    public Data: string;
    public DiasSemana: string;
    public AntecedenciaMinAllotment: number;
    public Reservados: number;
    public ReservadosPagos: number;
    public Disponivel: number;
}
export class OfflineCarroCategoriaDisponibilidadePeriodo {
    public OfflineCarroCategoria: any;
    public OfflineCarroCategoriaId: any;
    public Quantidade: any;
    public Operador: any; 
    public Data: string;
    public DiasSemana: string;
    public AntecedenciaMinAllotment: number;
    public Reservados: number;
    public ReservadosPagos: number;
    public Disponivel: number;
}
export class OfflineCarroManutencaoPeriodo {
    public Id: any;
    public OfflineCarro: any;
    public OfflineCarroId: any;
    public Descricao: string;
    public PeriodoDe: string;
    public PeriodoAte: string;
}
export class OfflineCarroPromocaoTarifa {
    public Id: any;
    public OfflineCarro: any;
    public OfflineCarroId: any;
    public Descricao: string;
    public NumeroDias: number;
    public GanhaNumeroDias: number;
    public Desconto: number;
    public TipoDescontoPercentual: boolean;
    public PeriodoDe: string;
    public PeriodoAte: string;
    public ValidadeDe: string;
    public ValidadeAte: string;
}



