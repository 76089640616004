import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import { Laminas, ItemLamina, PagamentoLamina } from '../../model';

import { API, ITENSPORPAGINA } from '../../app.config';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

const headers = new HttpHeaders().set('noloader', 't');
@Injectable()
export class LaminasService {
  constructor(private http: HttpClient) { }

  gerarImagemHTML(ItemLamina: ItemLamina, pagamentoLamina: PagamentoLamina) {

    ItemLamina.Pagamentos = pagamentoLamina;
    ItemLamina.Pagamentos.SimboloCambio = pagamentoLamina.CambioPagamento.Simbolo;

    return this.http.post<any>(`${API}/api/laminas/generateImage`, ItemLamina, { headers: headers })
  }

  buscarPorId(id: number): Observable<any> {
    return this.http.get<any>(`${API}/api/laminas/images/${id}`);
  }

  formasPagamento(id: number): Observable<any> {
    return this.http.get<any>(`${API}/api/laminas/pagamentosDisponiveis/${id}`);
  }

  remover(id: Number): Observable<any> {
    return this.http.delete<any>(`${API}/api/laminas/images/delete/${id}`);
  }

  editar(objetoClass: Laminas): Observable<any> {
    return this.http.put<any>(`${API}/api/laminas/images/edit/${objetoClass.Id}`, objetoClass);
  }

  buscarLaminas(): Observable<any> {
    return this.http.get<any>(`${API}/api/laminas/quantidadelaminasgeradas`);
  }

  uploadArquivo(arquivo: any = null, objetoClass: Laminas): Observable<any> {
    let transicao = JSON.stringify(objetoClass);
    const url = `${API}/api/laminas/images/upload`;
    const formData = new FormData();
    formData.append('Arquivo', arquivo);
    formData.append('Dados', transicao);
    return this.http.post(url, formData, { headers: headers });
  }

  ListaPorParametro(param: string): Observable<any> {
    if (param.length > 0) {
      return this.http.get<any>(`${API}/api/laminas/images/lista/${param.trim()}`, { headers: headers });
    } else {
      return this.http.get<any>(`${API}/api/laminas/images/lista`, { headers: headers });
    }
  }

  ListaPorParametroPaginada(param: string, pagina: Number): Observable<any> {
    if (param.length > 0) {
      return this.http.get<any>(`${API}/api/laminas/images/listaPaginada/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${8}`, { headers: headers });
    } else {
      return this.http.get<any>(`${API}/api/laminas/images/listaPaginada?Pagina=${pagina}&ItensPorPagina=${8}`, { headers: headers });
    }
  }

  ListaTemplatePaginada(pagina: Number): Observable<any> {
    return this.http.get<any>(`${API}/api/laminas/templates/listaPaginada?Pagina=${pagina}&ItensPorPagina=${9}`, { headers: headers });
  }

  buscarImagensUnsplash(parametro: string): Observable<any> {
    return this.http.get<any>(`${API}/api/laminas/buscar/unsplash?parametro=${parametro}`, { headers: headers });
  }
}
