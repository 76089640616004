import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { API } from '../app.config';

@Injectable()
export class Util {
    constructor(private translate: TranslateService) { }

    static pathImagemApi(nome: string): string {
        if (nome == null || nome == undefined || nome.length == 0) {
            return `/assets/img/notfound.jpg`;
        } else {
            return `${API}${nome}`;
        }

    }

    fazerTraducao(texto: string): string {
        try {
            return this.translate.instant(texto);
        } catch (e) {
            console.log(e);
            return texto;
        }
    }
}