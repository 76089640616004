
     <nb-layout>
       <nb-layout-column                 [class.black-color]="Black_FRT" [class.arkbeds-padding]="Arkbeds_Fundo" [class.frt-fundo]="FRT_Fundo" [class.backgroundImg]="FRT_Fundo">
        <nb-card                         [class.black-frt]="Black_FRT"   [class.arkbeds-banner]="Arkbeds_Fundo"  [class.transparent-frt]="FRT_Fundo || Arkbeds_Fundo">
          <nb-card-header                [class.border]="Black_FRT || Arkbeds_Fundo" [class.sem-aparecer]="FRT_Fundo || Arkbeds_Fundo">
            <nav class="navigation">
              <a href="#" (click)="back()" class="link" aria-label="Back">
                <i class="icon nb-arrow-thin-left"></i>
              </a>
            </nav>
          </nb-card-header>
          <nb-card-body class="row m-0 col-md-12 justify-content-center align-items-sm-center"[class.arkbeds-padding]="Arkbeds_Fundo">
            <router-outlet></router-outlet>
          </nb-card-body>
        </nb-card>
       </nb-layout-column>
     </nb-layout>
   